"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const redux_1 = require("redux");
const redux_thunk_1 = require("redux-thunk");
const InteractiveResumeReducer_1 = require("./reducers/InteractiveResumeReducer");
const ContactFormReducer_1 = require("./reducers/ContactFormReducer");
const HeaderMenuReducer_1 = require("./reducers/HeaderMenuReducer");
const react_router_redux_1 = require("react-router-redux");
let devTools = window['devToolsExtension'] && window['devToolsExtension']();
exports.default = redux_1.createStore(redux_1.combineReducers({
    headerState: HeaderMenuReducer_1.headerMenuReducer,
    resumeState: InteractiveResumeReducer_1.interactiveResumeReducer,
    contactState: ContactFormReducer_1.contactFormReducer,
    routing: react_router_redux_1.routerReducer,
}), devTools, redux_1.applyMiddleware(redux_thunk_1.default));
