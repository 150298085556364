"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Modal_1 = require("../constants/Modal");
const HeaderNav = ({ openModal, onTogglePause, onToggleControls, toggledPause, controls, resume = '/assets/resume/SimonMo.co.uk__resume-2019.pdf', }) => {
    return (React.createElement("header", { className: 'header' },
        React.createElement("span", { className: 'btn-secondary', title: 'Contact Me', onClick: () => {
                openModal({
                    name: Modal_1.default.CONTACT_FORM,
                });
            } },
            React.createElement("i", { className: 'fa fa-envelope' }),
            React.createElement("div", { className: 'label' }, "Contact Me")),
        React.createElement("a", { className: 'btn-secondary', href: resume, target: '_blank', title: 'Download my paper r\u00E9sum\u00E9' },
            React.createElement("i", { className: 'fa fa-file-pdf-o' }),
            React.createElement("div", { className: 'label' }, "Download my r\u00E9sum\u00E9")),
        React.createElement("span", { className: `btn-secondary${(controls.isOpen) ? ' active' : ''}`, title: 'Show Keyboard Controls', onClick: () => {
                onToggleControls();
            } },
            React.createElement("i", { className: 'fa fa-keyboard-o' }),
            React.createElement("div", { className: 'label' }, "Show Keyboard Controls")),
        React.createElement("span", { className: `btn-secondary${(toggledPause) ? ' active' : ' inactive'}`, title: 'Pause Animation', onClick: () => {
                onTogglePause();
            } },
            React.createElement("i", { className: `fa fa-${(toggledPause) ? 'pause' : 'play'}` }),
            React.createElement("div", { className: 'label' }, "Pause Animation"))));
};
exports.default = HeaderNav;
