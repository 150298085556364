"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ProfileImage_1 = require("./ProfileImage");
const Profile = (props) => {
    return (React.createElement("div", { className: 'profile' },
        React.createElement(ProfileImage_1.default, Object.assign({}, props)),
        React.createElement("div", { className: 'loading-status' }, props.loading.status)));
};
exports.default = Profile;
