"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Resume = require("../constants/ResumeInformation");
const ResumeInformation = ({ modal, actions, }) => {
    const info = Resume.all[modal.modalComponent];
    const logo = (info.logo.hasLogo) ? (React.createElement("div", { className: 'resume-information__logo col-3 col-sm-2 col-md-2 px-0 mb-1' },
        React.createElement("img", { src: `${info.logo.url}`, className: 'img-fluid' }))) : null;
    return (React.createElement("div", { className: `resume-information col-12 col-md-10 col-lg-8 col-xl-6 offset-md-1 offset-lg-2 offset-xl-3 mt-5` },
        React.createElement("div", { className: 'col-12 px-0' },
            logo,
            React.createElement("i", { className: 'btn-close fa fa-close fa-2x', onClick: () => {
                    actions.closeModal();
                } })),
        React.createElement("div", null, info.summary)));
};
exports.default = ResumeInformation;
