"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const LoadScene_1 = require("./LoadScene");
const Planet_1 = require("./Planet");
const background_1 = require("../utils/background");
const init = (canvas) => {
    const promiseArray = [
        LoadScene_1.default(canvas),
        Planet_1.default(canvas),
        background_1.default(),
    ];
    return Promise.all(promiseArray)
        .then(() => {
        canvas.animate();
        window['canvas'] = canvas;
        setTimeout(() => {
            canvas.event.publish('loading/status', '🖱 Click To Play');
            document.body.classList.add('loaded');
            document.body.onclick = () => {
                document.body.classList.remove('loading');
                document.body.classList.remove('loaded');
            };
            document.body.ontouchend = () => {
                document.body.classList.remove('loading');
                document.body.classList.remove('loaded');
            };
        }, 200);
    }).catch(e => new Error(e));
};
exports.default = init;
