"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Actions = require("../constants/ContactForm");
const InteractiveResumeActions_1 = require("./InteractiveResumeActions");
const es6_promise_1 = require("es6-promise");
const fetch = require("isomorphic-fetch");
es6_promise_1.polyfill();
exports.updateForm = (field, value) => {
    return (dispatch) => {
        dispatch(updateInput(field, value));
        dispatch(exports.validateForm());
    };
};
const updateInput = (field, value) => {
    return {
        type: Actions.UPDATE_INPUT,
        payload: {
            field,
            value,
        }
    };
};
exports.validateForm = () => {
    return {
        type: Actions.VALIDATE_FORM,
    };
};
const isSending = (payload) => {
    return {
        type: Actions.SENDING_EMAIL,
        payload,
    };
};
const getReplyEmail = (givenName, familyName, email) => {
    return !!(givenName && familyName && email) ? `${givenName} ${familyName} <${email}>` : '';
};
exports.sendEmail = () => {
    return (dispatch, getState) => {
        const { contactState } = getState();
        const { givenName, familyName, email, subject, message, } = contactState;
        dispatch(isSending(true));
        fetch('https://brwloouhm2.execute-api.eu-west-1.amazonaws.com/staging/notify', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
            },
            body: JSON.stringify({
                eMail: {
                    name: givenName.value,
                    surname: familyName.value,
                    replyTo: getReplyEmail(givenName.value, familyName.value, email.value),
                    from: email.value,
                    subject: subject.value,
                    body: message.value
                }
            }),
        }).then((response) => {
            if (response.status >= 400) {
                throw new Error('Bad response from server');
            }
            return response.json();
        })
            .then(() => {
            dispatch(isSending(false));
            dispatch(exports.sentEmail(true));
            setTimeout(() => {
                dispatch(InteractiveResumeActions_1.closeModal());
            }, 1000);
        });
    };
};
exports.sentEmail = (payload) => {
    return {
        type: Actions.SEND_EMAIL,
        payload,
    };
};
