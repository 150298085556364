"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const GULP = {
    name: 'Gulp',
    url: 'http://gulpjs.com/',
    summary: `Gulp.js is an automation tool that I use to automate my workflow. Mainly I use it to automate the ` +
        `compiling of typescript files into javascript and then transpiling the output from es6 to es5 to ` +
        `ensure compatibility with web clients. For node js server applications I normally leave the files in ` +
        `es6 as es6 is supported serverside. I also use gulp for compiling SASS or LESS to CSS and for many ` +
        `other tasks`,
    logo: {
        hasLogo: true,
        url: 'assets/images/logos/gulp-white.svg'
    },
};
const AWS_LAMBDA = {
    name: 'AWS Lambda',
    url: '',
    summary: `AWS Lambda is Amazon's cloud function platform. I used AWS Lambda at the LADbible group to build a ` +
        `new prototype to demo an alternative version of their publishing pipeline. The early results that this ` +
        `pipeline produced showed us that using cloud functions to build concurrently could save the company ` +
        `upwards of 1950 hours per year compared to the old publishing pipeline.`,
    logo: {
        hasLogo: true,
        url: 'assets/images/logos/aws-lambda.svg'
    },
};
const CSS3 = {
    name: 'CSS3',
    url: '',
    summary: `I have a long history with CSS. In fact I witnessed first hand the evolution of CSS from version 1 to ` +
        `todays version 3. When CSS initially came out, I was still in the table mindset. I had a hard time ` +
        `believing that it would ever catch on. I was wrong and now I am reminded every day that I was wrong.`,
    logo: {
        hasLogo: true,
        url: 'assets/images/logos/css3.svg'
    },
};
const HTML5 = {
    name: 'HTML5',
    url: '',
    summary: `I started developing when HTML was still using Doctype 4 then over the years as technology advanced ` +
        `I progressed to use HTML 5. Today I mostly write my markup in JSX as I use alot of React components. ` +
        `Although there are still times, for example when I am templating that I am still required to write ` +
        `raw html.`,
    logo: {
        hasLogo: true,
        url: 'assets/images/logos/html5.svg'
    },
};
const HANDLEBARS = {
    name: 'Handlebars',
    url: '',
    summary: `Handlbars is an awesome templating language. I like using it, and I use it almost daily, because ` +
        `it is incredibly easy to extend functionality, for example converting date formats, sorting data, ` +
        `using regex to replace strings or debugging.`,
    logo: {
        hasLogo: true,
        url: 'assets/images/logos/handlebars.svg'
    },
};
const JAVASCRIPT = {
    name: 'Javascript ES6/7',
    url: '',
    summary: `Javascript is currently one of my favourite languages to work with. Its my favourite because you can ` +
        `pretty much use it anywhere. Whether it's on the server or client side. I particularly like the fact ` +
        `that most of the tools I use run off node, so it makes it extremely versatile as it's very easy ` +
        `to create new tools or adapt my my current tooling setup to suit my needs as the project ` +
        `requirements change`,
    logo: {
        hasLogo: true,
        url: 'assets/images/logos/javascript.svg',
    },
};
const LAD_BIBLE = {
    name: 'LADbible Group',
    url: 'http://www.ladbible.com/',
    summary: `Every month the LADbible group recieves over 500+ million hits which consisting of 25 million unique ` +
        `visitors. In terms of data, this equates to 300+ tera bytes of data, which if you were to print out on paper ` +
        `would be the size of roughly 3 million yellow pages.`,
    logo: {
        hasLogo: true,
        url: 'assets/images/logos/lad-bible.svg',
    },
};
const NBROWN = {
    name: 'N Brown Group PLC',
    url: 'http://www.nbrown.co.uk',
    summary: `N Brown Group is a leading digital fashion retailer, with over 140 years of experience. ` +
        `Their three main power brands consists of Jacamo, SimplyBe and JD Williams.`,
    logo: {
        hasLogo: true,
        url: 'assets/images/logos/nbrown.svg',
    },
};
const TECMARK = {
    name: 'Tecmark Limited',
    url: 'http://www.tecmark.co.uk',
    summary: `Tecmark started life in 2008 as an SEO agency in Manchester with a reputation for consistently ` +
        `delivering ROI in competitive areas.`,
    logo: {
        hasLogo: true,
        url: 'assets/images/logos/tecmark.svg',
    },
};
exports.work = {
    'lad-bible': LAD_BIBLE,
    'nbrown': NBROWN,
    'tecmark': TECMARK,
};
exports.tech = {
    'gulp-cup': GULP,
    'aws-lambda': AWS_LAMBDA,
    'css-3': CSS3,
    'handlebars': HANDLEBARS,
    'html-5': HTML5,
    'javascript': JAVASCRIPT,
};
exports.environment = {};
exports.actions = {
    'satellite': () => { },
};
exports.all = Object.assign({}, exports.work, exports.tech, exports.environment);
exports.keys = Object.keys(exports.all);
exports.actionKeys = Object.keys(exports.actions);
