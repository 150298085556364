"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Actions = require("../constants/Generic");
exports.loadingPercentage = (percentage) => {
    return {
        type: Actions.LOADING_PERCENTAGE,
        payload: percentage,
    };
};
exports.loadingStatus = (status) => {
    return {
        type: Actions.LOADING_STATUS,
        payload: status,
    };
};
const attachModal = (payload = {
    status: false,
    object: {
        name: '',
    },
}) => {
    return {
        type: Actions.ATTACH_MODAL,
        payload,
    };
};
const fadeModal = (payload) => {
    return {
        type: Actions.FADE_MODAL,
        payload,
    };
};
exports.activateSearchBar = () => {
    return {
        type: Actions.TOGGLE_SEARCH,
    };
};
exports.setSearchBarIsActive = (payload) => {
    return {
        type: Actions.SET_SEARCH_BAR_STATUS,
        payload
    };
};
exports.onPause = (payload) => {
    return {
        type: Actions.SET_PAUSE,
        payload
    };
};
exports.openModal = (payload = { name: '', }) => {
    return (dispatch) => {
        dispatch(attachModal({
            status: true,
            object: payload,
        }));
        setTimeout(() => {
            dispatch(fadeModal('in'));
        }, 200);
    };
};
exports.closeModal = (payload = { name: '', }) => {
    return (dispatch) => {
        dispatch(fadeModal('out'));
        setTimeout(() => {
            dispatch(attachModal({
                status: false,
                object: payload,
            }));
        }, 200);
    };
};
exports.updateSearchBarValue = (payload) => {
    return {
        type: Actions.UPDATE_SEARCH_VALUE,
        payload,
    };
};
exports.clearSearchBarData = () => {
    return {
        type: Actions.CLEAR_SEARCH,
    };
};
exports.togglePause = () => {
    return {
        type: Actions.TOGGLE_PAUSE,
    };
};
exports.attachControls = () => {
    return {
        type: Actions.ATTACH_CONTROLS,
    };
};
exports.fadeControls = () => {
    return {
        type: Actions.FADE_CONTROLS,
    };
};
exports.toggleControls = () => {
    return (dispatch, getState) => {
        const { isOpen } = getState().resumeState.controls;
        const functionA = (isOpen) ? exports.fadeControls : exports.attachControls;
        const functionB = (isOpen) ? exports.attachControls : exports.fadeControls;
        const delay = (isOpen) ? 400 : 150;
        dispatch(functionA());
        setTimeout(() => {
            dispatch(functionB());
        }, delay);
    };
};
exports.hoveredOverObject = (payload) => {
    return {
        type: Actions.HOVERED_OVER_OBJECT,
        payload,
    };
};
