"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_lifecycle_component_1 = require("react-lifecycle-component");
const redux_1 = require("redux");
const Actions = require("../actions/ContactFormActions");
const InteractiveResumeActions_1 = require("../actions/InteractiveResumeActions");
const ContactForm_1 = require("../layouts/ContactForm");
const mapStateToProps = (state) => {
    return {
        contactState: state.contactState,
        givenNameValue: state.contactState.givenName.value,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: redux_1.bindActionCreators(Actions, dispatch),
        onCancel: () => {
            const closeModalAction = InteractiveResumeActions_1.closeModal();
            dispatch(closeModalAction);
        },
    };
};
const ContactFormContainer = react_lifecycle_component_1.connectWithLifecycle(mapStateToProps, mapDispatchToProps)(ContactForm_1.default);
exports.default = ContactFormContainer;
