"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const React = require("react");
const Resume = require("../constants/ResumeInformation");
const Canvas_1 = require("../components/Canvas");
class Controls extends React.Component {
    constructor(props) {
        super(props);
        this.canvas = props.canvas;
        this.event = props.canvas.event;
        this.isMobile = props.isMobile;
        this.anchor = new THREE.Vector2(0, 0);
        this.currentPoint = new THREE.Vector2(0, 0);
        this.rotateDirection = new THREE.Vector2(0, 0);
        this.isRotating = false;
        this.rotateFactor = 0.0005;
        this.touchStart = 0;
        this.joystickTouchZone = 75;
        this.joystickIsActive = false;
        this.onTap = this.onTap.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.openModal = this.openModal.bind(this);
        this.keyEventListeners = this.keyEventListeners.bind(this);
        this.mouseEventListeners = this.mouseEventListeners.bind(this);
        this.touchEventListeners = this.touchEventListeners.bind(this);
        this.subscribeToKey = this.subscribeToKey.bind(this);
        this.subscribeToMouse = this.subscribeToMouse.bind(this);
        this.subscribeToAll = this.subscribeToAll.bind(this);
        this.createRotation = this.createRotation.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.setRaycaster = this.setRaycaster.bind(this);
        this.isHoveredOverClickableObject = this.isHoveredOverClickableObject.bind(this);
        this.debouceTime = this.debouceTime.bind(this);
        this.isAndroid = this.isAndroid.bind(this);
        this.attachJoystick = this.attachJoystick.bind(this);
        this.removeJoystick = this.removeJoystick.bind(this);
        this.createCircle = this.createCircle.bind(this);
        this.decelerateRotation = this.decelerateRotation.bind(this);
        this.joystickIsInBounds = this.joystickIsInBounds.bind(this);
        if (this.isMobile) {
            this.touchEventListeners();
            this.subscribeToTouch();
        }
        else {
            this.keyEventListeners();
            this.mouseEventListeners();
            this.subscribeToKey();
            this.subscribeToMouse();
        }
        this.subscribeToAll();
    }
    isAndroid(event) {
        if (navigator.userAgent.match(/Android/i)) {
            event.preventDefault();
        }
    }
    keyEventListeners() {
        document.addEventListener('keyup', (event) => {
            this.event.publish('onkeyup', event);
        });
        document.addEventListener('keydown', (event) => {
            this.event.publish('onkeydown', event);
        });
    }
    mouseEventListeners() {
        document.addEventListener('mousedown', (event) => {
            this.touchStart = Date.now();
            this.event.publish('onmousedown', event);
        });
        document.addEventListener('mousemove', (event) => {
            if (this.debouceTime(150)) {
                return;
            }
            this.event.publish('onmousemove', event);
        });
        document.addEventListener('mouseup', (event) => {
            this.event.publish('onmouseup', event);
        });
    }
    debouceTime(debounceInMs) {
        return (Date.now() - this.touchStart) < debounceInMs;
    }
    joystickIsInBounds(touch) {
        const x = Math.pow((touch.clientX - this.anchor.x), 2);
        const y = Math.pow((touch.clientY - this.anchor.y), 2);
        const d = Math.sqrt(x + y);
        return (d <= this.joystickTouchZone);
    }
    attachJoystick(event) {
        const joyStickBase = this.canvas.scene.getObjectByName('joystick-base');
        const joyStickBall = this.canvas.scene.getObjectByName('joystick-ball');
        const zoomScale = 1 / this.canvas.camera.zoom;
        const touch = event.touches.item(0);
        let touchX = (touch.clientX / window.innerWidth) * 2 - 1;
        let touchY = -(touch.clientY / window.innerHeight) * 2 + 1;
        let vector = new THREE.Vector3(touchX, touchY, 0.5).unproject(this.canvas.camera);
        let direction = vector.sub(this.canvas.camera.position).normalize();
        let position = this.canvas.camera.position.clone().add(direction.multiplyScalar(10));
        if (joyStickBase && joyStickBall) {
            if (!this.joystickIsInBounds(touch)) {
                const angle = Math.atan2(touch.clientY - this.anchor.y, touch.clientX - this.anchor.x) - 1.57059633;
                const xDistance = Math.sin(angle) * this.joystickTouchZone;
                const yDistance = Math.cos(angle) * this.joystickTouchZone;
                direction = new THREE.Vector3(-xDistance, -yDistance, 0).normalize();
                position = joyStickBase.position.clone().add(direction);
            }
            joyStickBall.position.copy(position);
            return;
        }
        this.createCircle('joystick-base', position, 0x666666, 0.9 * zoomScale);
        this.createCircle('joystick-ball', position, 0x444444, 0.5 * zoomScale);
        this.joystickIsActive = true;
    }
    createCircle(name, position, color, size) {
        const geometry = new THREE.CircleGeometry(size, 72);
        const material = new THREE.MeshLambertMaterial({
            color: color,
            opacity: 0.5,
        });
        material.transparent = true;
        const joyStickBaseCircle = new THREE.Mesh(geometry, material);
        joyStickBaseCircle.name = name;
        joyStickBaseCircle.position.copy(position);
        this.canvas.scene.add(joyStickBaseCircle);
    }
    removeJoystick() {
        const joystickBase = this.canvas.scene.getObjectByName('joystick-base');
        const joyStickBall = this.canvas.scene.getObjectByName('joystick-ball');
        if (joystickBase && joyStickBall) {
            this.canvas.scene.remove(joystickBase);
            this.canvas.scene.remove(joyStickBall);
        }
        this.joystickIsActive = false;
    }
    swipeDistanceIsMoreThan(touch, minimumDistance = 50) {
        const distance = touch.touches.item(0);
        const xDistance = Math.abs(this.anchor.x - distance.clientX);
        const yDistance = Math.abs(this.anchor.y - distance.clientY);
        return (xDistance > minimumDistance) || (yDistance > minimumDistance);
    }
    touchEventListeners() {
        document.addEventListener('touchstart', (event) => {
            this.isAndroid(event);
            this.touchStart = Date.now();
            this.event.publish('ontouchstart', event);
        });
        document.addEventListener('touchmove', (event) => {
            if (this.debouceTime(150) && (this.swipeDistanceIsMoreThan(event))) {
                return;
            }
            this.isAndroid(event);
            this.isRotating = true;
            this.event.publish('ontouchmove', event);
        });
        document.addEventListener('touchend', (event) => {
            if (this.debouceTime(150) || !this.joystickIsActive) {
                this.onTap(event);
            }
            this.touchStart = 0;
            this.removeJoystick();
            this.event.publish('ontouchend', event);
        });
    }
    subscribeToKey() {
        this.event.subscribe('onkeyup', () => {
            if (this.preventAction()) {
                return;
            }
            this.onKeyUp();
        });
        this.event.subscribe('onkeydown', e => {
            if (this.preventAction()) {
                return;
            }
            this.onKeyDown(e);
        });
    }
    subscribeToTouch() {
        this.event.subscribe('ontouchstart', (event) => {
            if (this.preventAction()) {
                return;
            }
            const touch = event.touches.item(0);
            this.anchor = new THREE.Vector2(touch.clientX, touch.clientY);
        });
        this.event.subscribe('ontouchmove', (event) => {
            if (this.preventAction()) {
                return;
            }
            const touch = event.touches.item(0);
            if (!this.isRotating) {
                return;
            }
            this.currentPoint = new THREE.Vector2(touch.clientX, touch.clientY);
            this.attachJoystick(event);
        });
        this.event.subscribe('ontouchend', () => {
            if (this.preventAction()) {
                return;
            }
            setTimeout(() => {
                this.isRotating = false;
            }, 150);
        });
    }
    subscribeToMouse() {
        this.event.subscribe('onmousedown', (event) => {
            if (this.preventAction() || this.isHoveredOverClickableObject()) {
                return;
            }
            this.isRotating = true;
            this.anchor = new THREE.Vector2(event.clientX, event.clientY);
        });
        this.event.subscribe('onmousemove', (event) => {
            if ((this.preventAction() || !this.isRotating) && this.debouceTime(150)) {
                return;
            }
            this.currentPoint = new THREE.Vector2(event.clientX, event.clientY);
        });
        this.event.subscribe('onmousemove', e => {
            if (this.preventAction()) {
                return;
            }
            this.onMouseMove(e);
        });
        this.event.subscribe('onmouseup', () => {
            if (this.preventAction()) {
                return;
            }
            setTimeout(() => {
                this.isRotating = false;
            }, 150);
        });
        this.event.subscribe('onmouseup', e => {
            if (this.preventAction()) {
                return;
            }
            this.onMouseUp(e);
        });
    }
    subscribeToAll() {
        this.event.subscribe('animate/bypass-pause', () => {
            if (this.preventAction()) {
                return;
            }
            this.createRotation();
        });
    }
    decelerateRotation() {
        const { autoRotate, rotateSpeed } = this.canvas;
        autoRotate.set(this.rotateDirection.x, this.rotateDirection.y);
        const decelerate = setInterval(() => {
            autoRotate.sub(new THREE.Vector2(0.1, 0.1));
            if (rotateSpeed >= autoRotate.x && rotateSpeed >= autoRotate.y) {
                clearInterval(decelerate);
                this.canvas.rotateSpeed = rotateSpeed;
            }
        }, 100);
    }
    createRotation() {
        if (!this.isRotating) {
            return;
        }
        const { xAxis, yAxis } = this.canvas;
        const moveX = this.currentPoint.x - this.anchor.x;
        const moveY = this.currentPoint.y - this.anchor.y;
        this.rotateDirection = new THREE.Vector2(moveX * this.rotateFactor, moveY * this.rotateFactor);
        this.canvas.rotate(yAxis, moveX * this.rotateFactor);
        this.canvas.rotate(xAxis, moveY * this.rotateFactor);
    }
    preventAction() {
        return (this.canvas.props.searchBarIsActive || this.canvas.props.modalIsOpen);
    }
    onKeyUp() {
        this.canvas.deBounceStop = setTimeout(() => {
            this.canvas.setdirection(Canvas_1.Direction.STATIONARY);
        }, 500);
    }
    onKeyDown(e) {
        let { deBounceStop } = this.canvas;
        clearTimeout(deBounceStop);
        switch (e.keyCode) {
            case 38:
            case 87:
                this.canvas.setdirection(Canvas_1.Direction.UP);
                break;
            case 40:
            case 83:
                this.canvas.setdirection(Canvas_1.Direction.DOWN);
                break;
            case 39:
            case 68:
                this.canvas.setdirection(Canvas_1.Direction.RIGHT);
                break;
            case 37:
            case 65:
                this.canvas.setdirection(Canvas_1.Direction.LEFT);
                break;
            case 189:
                this.canvas.zoomCamera(-0.5);
                break;
            case 187:
                this.canvas.zoomCamera(+0.5);
                break;
            case 32:
                this.canvas.props.togglePause();
                break;
        }
    }
    onMouseMove(event) {
        this.canvas.updateMousePosition(event);
        this.setRaycaster(true);
    }
    onMouseUp(event) {
        this.canvas.updateMousePosition(event);
        this.setRaycaster();
        this.openModal();
    }
    onTap(event) {
        this.canvas.updateMousePosition(event.changedTouches.item(0));
        this.setRaycaster();
        this.openModal();
    }
    openModal() {
        if (this.canvas.hovered && !this.canvas.props.modalIsOpen) {
            switch (true) {
                case (Resume.keys.indexOf(this.canvas.hovered.name) !== -1):
                    this.event.publish('clicked/object', {
                        name: this.canvas.hovered.name,
                    });
                    break;
                case (Resume.actionKeys.indexOf(this.canvas.hovered.name) !== -1):
                    this.event.publish('clicked/satellite', {
                        name: this.canvas.hovered.name,
                    });
                    break;
            }
        }
    }
    isHoveredOverClickableObject() {
        this.canvas.raycaster.setFromCamera(this.canvas.mousePosition, this.canvas.camera);
        const intersects = this.canvas.raycaster.intersectObjects(this.canvas.models.planet.children);
        if (intersects.length > 0) {
            const hovered = intersects[0].object;
            return (Resume.keys.indexOf(hovered.name) !== -1);
        }
        return false;
    }
    setRaycaster(noInteraction = false) {
        if (!this.canvas.props.toggledPause) {
            this.event.publish('pause', false);
        }
        this.canvas.raycaster.setFromCamera(this.canvas.mousePosition, this.canvas.camera);
        const intersects = this.canvas.raycaster.intersectObjects(this.canvas.models.planet.children);
        this.canvas.props.hoveredOverObject(false);
        if (this.canvas.hovered && !this.props.isMobile) {
            this.canvas.hovered.material.color.setHex(0xFFFFFF);
        }
        if (intersects.length > 0) {
            this.canvas.hovered = intersects[0].object;
            if (!noInteraction) {
            }
            switch (true) {
                case Resume.keys.indexOf(this.canvas.hovered.name) !== -1:
                    this.event.publish('pause', true);
                    this.canvas.props.hoveredOverObject(true);
                    if (!this.props.isMobile) {
                        this.canvas.hovered.material.color.setHex(0x1D87AD);
                    }
                    return;
                default:
                    break;
            }
        }
        else {
            this.canvas.hovered = null;
        }
    }
    render() {
        return null;
    }
}
exports.default = Controls;
