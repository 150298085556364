"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const InputField_1 = require("../components/InputField");
const TextArea_1 = require("../components/TextArea");
const ContactForm = ({ actions, contactState, onCancel = () => { }, }) => {
    const { isSending, sentEmail, givenName, familyName, email, subject, message, valid, } = contactState;
    let submit = null;
    if (!sentEmail) {
        const disabled = !valid || isSending;
        let text = 'Incomplete Submission';
        if (isSending) {
            text = 'Sending';
        }
        else if (!isSending && !disabled) {
            text = 'Send';
        }
        submit = React.createElement("button", { className: 'btn btn-primary col-12', disabled: disabled, onClick: (e) => {
                e.preventDefault();
                actions.sendEmail();
            } }, text);
    }
    else if (sentEmail) {
        submit = React.createElement("h3", null, "Thank you for contacting me");
    }
    return (React.createElement("form", { className: 'contact-form col-12 col-md-10 col-lg-8 col-xl-6 offset-md-1 offset-lg-2 offset-xl-3 mt-5' },
        React.createElement("h1", null, "Get in touch"),
        React.createElement("i", { className: 'btn-close fa fa-close fa-2x', onClick: () => {
                onCancel();
            } }),
        React.createElement("small", { className: 'mb-3' }, "* All fields are required"),
        React.createElement("div", { className: 'row mx-0' },
            React.createElement(InputField_1.default, { label: 'Name', customClasses: ' mb-lg-3 px-0 pl-sm-0 pr-sm-1 col-sm-6 pl-lg-0 pr-lg-1', fieldName: 'first-name', valid: givenName.valid, isActive: givenName.isActive, placeholder: 'First name', name: 'given-name', onChange: (e) => {
                    actions.updateForm('givenName', e.target.value);
                } }),
            React.createElement(InputField_1.default, { label: 'Surname', customClasses: ' mb-lg-3 px-0 pl-sm-1 pr-sm-0 col-sm-6 pl-lg-2 pr-lg-0', fieldName: 'last-name', valid: familyName.valid, isActive: familyName.isActive, placeholder: 'Last name', name: 'family-name', onChange: (e) => {
                    actions.updateForm('familyName', e.target.value);
                } })),
        React.createElement(InputField_1.default, { label: 'E-mail', fieldName: 'e-mail', valid: email.valid, isActive: email.isActive, placeholder: 'Enter your e-mail', name: 'email', onChange: (e) => {
                actions.updateForm('email', e.target.value);
            } }),
        React.createElement(InputField_1.default, { label: 'Subject', fieldName: 'subject', valid: subject.valid, isActive: subject.isActive, placeholder: 'Subject', name: 'subject', onChange: (e) => {
                actions.updateForm('subject', e.target.value);
            } }),
        React.createElement(TextArea_1.default, { label: 'Subject', fieldName: 'message', valid: message.valid, isActive: message.isActive, placeholder: 'Enter your message here', name: 'message', onChange: (e) => {
                actions.updateForm('message', e.target.value);
            } }),
        submit));
};
exports.default = ContactForm;
