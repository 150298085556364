"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const React = require("react");
const PropTypes = require("prop-types");
const KeyboardControls_1 = require("../components/KeyboardControls");
const HeaderNav_1 = require("../components/HeaderNav");
const event_1 = require("../utils/event");
const Init_1 = require("../loaders/Init");
const Modal_1 = require("../constants/Modal");
const Controls_1 = require("./Controls");
const ZoomControls_1 = require("../components/ZoomControls");
exports.Direction = {
    STATIONARY: 'STATIONARY',
    UP: 'UP',
    DOWN: 'DOWN',
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
};
class Canvas extends React.Component {
    constructor(props) {
        super(props);
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 2000);
        this.renderer = new THREE.WebGLRenderer({
            antialias: !/(Safar)/.test(window.navigator.appVersion),
            alpha: true,
        });
        this.container = document.body;
        this.event = new event_1.default();
        this.ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
        this.dayLight = new THREE.DirectionalLight(0xffffff, 0.5);
        this.nightLight = new THREE.DirectionalLight(0x000066, 0.5);
        this.environment = new THREE.Object3D();
        this.models = {};
        this.moveSpeed = 0.017453 / 3;
        this.rotateSpeed = 0.017453 / 2;
        this.mousePosition = new THREE.Vector2();
        this.autoRotate = new THREE.Vector2(0, this.rotateSpeed / 3);
        this.deBounceStop = null;
        this.hovered = null;
        this.direction = 'STATIONARY';
        this.raycaster = new THREE.Raycaster();
        this.quaternion = new THREE.Quaternion();
        this.xAxis = new THREE.Vector3(1, 0, 0);
        this.yAxis = new THREE.Vector3(0, 1, 0);
        this.loading = 0;
        this.state = {
            usedKeyboard: false,
            finishedInit: false,
        };
        this.touch = {
            touchX: 0,
            touchY: 0,
        };
        if (!!window.location.hash) {
            this.addDebugAxisToObject(1000, this.environment);
        }
        this.animate = this.animate.bind(this);
        this.rotate = this.rotate.bind(this);
        this.updatePlanet = this.updatePlanet.bind(this);
        this.resizeCamera = this.resizeCamera.bind(this);
        this.pauseAnimation = this.pauseAnimation.bind(this);
        this.updateMousePosition = this.updateMousePosition.bind(this);
        this.zoomCamera = this.zoomCamera.bind(this);
        this.onResize = this.onResize.bind(this);
        this.renderer.context.getShaderInfoLog = function () {
            return '';
        };
    }
    animate() {
        requestAnimationFrame(this.animate);
        if (this.props.modalIsOpen) {
            return;
        }
        if (!this.props.toggledPause) {
            this.event.publish('animate', this);
        }
        this.event.publish('animate/bypass-pause', this);
        this.renderCanvas();
    }
    renderCanvas() {
        this.renderer.render(this.scene, this.camera);
    }
    addDebugAxisToObject(axisLength, target) {
        const v = (x, y, z) => {
            return new THREE.Vector3(x, y, z);
        };
        const createAxis = (p1, p2, color, target) => {
            let line, lineGeometry = new THREE.Geometry(), lineMat = new THREE.LineBasicMaterial({ color: color });
            lineGeometry.vertices.push(p1, p2);
            line = new THREE.Line(lineGeometry, lineMat);
            target.add(line);
        };
        createAxis(v(-axisLength, 0, 0), v(axisLength, 0, 0), 0xFF0000, target);
        createAxis(v(0, -axisLength, 0), v(0, axisLength, 0), 0x00FF00, target);
        createAxis(v(0, 0, -axisLength), v(0, 0, axisLength), 0x0000FF, target);
    }
    componentDidMount() {
        this.event.subscribe('loading/status', (e) => {
            this.props.loadingStatus(e);
        });
        Init_1.default(this).then(() => {
            this.setState({
                finishedInit: true,
            });
            window.addEventListener('orientationchange', this.onResize);
            window.addEventListener('resize', this.onResize);
            this.event.subscribe('animate', this.updatePlanet);
            this.event.subscribe('pause', this.pauseAnimation);
            this.event.subscribe('resize', this.resizeCamera);
            this.event.subscribe('clicked/object', this.props.openModal);
        });
    }
    onResize(event) {
        this.event.publish('resize', event);
        this.renderCanvas();
    }
    setdirection(direction) {
        this.setState(Object.assign(this.state, {
            usedKeyboard: true,
        }));
        this.direction = direction;
    }
    zoomCamera(override = null) {
        const zoomFactor = (this.props.isMobile) ? 0.5 : 0.5;
        if (!override) {
            this.camera.zoom += (this.camera.zoom >= (3 * zoomFactor)) ? (-2 * zoomFactor) : 1;
        }
        else {
            const zoom = (override * zoomFactor);
            const proposedZoom = this.camera.zoom + zoom;
            if (proposedZoom > 3 || proposedZoom < 0.5) {
                return;
            }
            this.camera.zoom += zoom;
        }
        this.camera.updateProjectionMatrix();
    }
    rotate(axis, angle) {
        const { quaternion, environment } = this;
        quaternion.setFromAxisAngle(axis, angle);
        environment.quaternion.premultiply(quaternion);
    }
    resizeCamera() {
        let { camera, renderer } = this;
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
    }
    pauseAnimation(pause) {
        this.props.isPaused(pause);
    }
    updateMousePosition(event) {
        const x = event.clientX || 0;
        const y = event.clientY || 0;
        this.mousePosition.x = (x / window.innerWidth) * 2 - 1;
        this.mousePosition.y = -((y - 2) / window.innerHeight) * 2 + 1;
    }
    updatePlanet() {
        let { direction, moveSpeed, rotateSpeed, xAxis, yAxis } = this;
        switch (direction) {
            case exports.Direction.UP:
                this.rotate(xAxis, moveSpeed);
                break;
            case exports.Direction.DOWN:
                this.rotate(xAxis, -moveSpeed);
                break;
            case exports.Direction.RIGHT:
                this.rotate(yAxis, rotateSpeed);
                break;
            case exports.Direction.LEFT:
                this.rotate(yAxis, -rotateSpeed);
                break;
            default:
                if (!this.props.pause) {
                    this.rotate(xAxis, this.autoRotate.x);
                    this.rotate(yAxis, this.autoRotate.y);
                }
                break;
        }
    }
    render() {
        return (React.createElement("div", { className: `canvas-interactive-resume ${(this.props.isHovered) ? 'hovered' : ''}` },
            React.createElement(HeaderNav_1.default, { openModal: this.props.openModal, toggledPause: this.props.toggledPause, onToggleControls: () => {
                    this.props.openModal({
                        name: Modal_1.default.KEYBOARD_CONTROLS,
                    });
                }, onTogglePause: () => {
                    this.pauseAnimation(!this.props.pause);
                    this.props.togglePause();
                }, controls: this.props.controls }),
            React.createElement(KeyboardControls_1.default, { controls: this.props.controls, onCancel: () => {
                    this.props.toggleControls();
                }, isMobile: this.props.isMobile }),
            React.createElement(ZoomControls_1.default, { zoom: this.zoomCamera }),
            (this.state.finishedInit) ? React.createElement(Controls_1.default, { canvas: this, isMobile: this.props.isMobile }) : null));
    }
}
Canvas.propTypes = {
    isPaused: PropTypes.func.isRequired,
    pause: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isHovered: PropTypes.bool.isRequired,
    updateLoadingBar: PropTypes.func.isRequired,
    hoveredOverObject: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
    searchBarIsActive: PropTypes.bool.isRequired,
    loadingStatus: PropTypes.func.isRequired,
    togglePause: PropTypes.func.isRequired,
    toggledPause: PropTypes.bool.isRequired,
    toggleControls: PropTypes.func.isRequired,
    controls: PropTypes.shape({
        isOpen: PropTypes.bool.isRequired,
        controlsClass: PropTypes.string.isRequired,
    }),
};
exports.default = Canvas;
