"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_lifecycle_component_1 = require("react-lifecycle-component");
const redux_1 = require("redux");
const Actions = require("../actions/InteractiveResumeActions");
const InteractiveResume_1 = require("../layouts/InteractiveResume");
const mapStateToProps = ({ resumeState, headerState, }) => {
    return Object.assign({}, resumeState, { stackOverflow: headerState.stackOverflow });
};
const mapDispatchToProps = (dispatch, props) => {
    return {
        actions: redux_1.bindActionCreators(Actions, dispatch),
        componentDidMount: () => {
        },
        componentWillReceiveProps: nextProps => {
            const { updatedAt } = props;
            if (nextProps.updatedAt > updatedAt) {
                this.forceUpdate();
            }
        },
    };
};
const InteractiveResumeContainer = react_lifecycle_component_1.connectWithLifecycle(mapStateToProps, mapDispatchToProps)(InteractiveResume_1.default);
exports.default = InteractiveResumeContainer;
