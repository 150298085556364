"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const KeyboardControls = ({ controls = {
    isOpen: true,
    controlsClass: 'in',
}, onCancel = () => { }, isMobile = false, }) => {
    let instructions = (React.createElement("div", { className: 'keyboard-controls__container text-center' },
        React.createElement("div", { className: 'keyboard-controls__row mb-3' },
            React.createElement("div", { className: 'small' }, "Use the arrow keys to rotate and space bar to zoom."),
            React.createElement("i", { className: 'btn-close fa fa-close fa-2x', onClick: () => {
                    onCancel();
                } })),
        React.createElement("div", { className: 'direction-controls col-9' },
            React.createElement("div", { className: 'keyboard-controls__row' },
                React.createElement("div", { className: 'keyboard-controls__key keyboard-controls__up-w' },
                    React.createElement("span", null, "\u25B2\uFE0E"),
                    React.createElement("div", { className: 'label' }, "Rotate Planet Forwards"))),
            React.createElement("div", { className: 'keyboard-controls__row' },
                React.createElement("div", { className: 'keyboard-controls__key keyboard-controls__left-a' },
                    React.createElement("span", null, "\u25C0\uFE0E"),
                    React.createElement("div", { className: 'label' }, "Rotate Planet Left")),
                React.createElement("div", { className: 'keyboard-controls__key keyboard-controls__down-s' },
                    React.createElement("span", null, "\u25BC\uFE0E"),
                    React.createElement("div", { className: 'label' }, "Rotate Planet Backwards")),
                React.createElement("div", { className: 'keyboard-controls__key keyboard-controls__right-d' },
                    React.createElement("span", null, "\u25B6\uFE0E"),
                    React.createElement("div", { className: 'label' }, "Rotate Planet Right")))),
        React.createElement("div", { className: 'keyboard-controls__zoom-controls col-3' },
            React.createElement("div", { className: 'keyboard-controls__key keyboard-controls__plus' },
                React.createElement("span", null, "+"),
                React.createElement("div", { className: 'label' }, "Zoom In")),
            React.createElement("div", { className: 'keyboard-controls__key keyboard-controls__minus' },
                React.createElement("span", null, "-"),
                React.createElement("div", { className: 'label' }, "Zoom Out"))),
        React.createElement("div", { className: 'keyboard-controls__row' },
            React.createElement("div", { className: 'keyboard-controls__space-bar' },
                React.createElement("span", null, "Space Bar"),
                React.createElement("div", { className: 'label' }, "Pause Animation")))));
    if (isMobile) {
        instructions = (React.createElement("div", { className: 'keyboard-controls__container text-left' },
            React.createElement("div", { className: 'keyboard-controls__row mb-3' },
                React.createElement("h2", null, "Mobile Controls"),
                React.createElement("i", { className: 'btn-close fa fa-close fa-2x', onClick: () => {
                        onCancel();
                    } })),
            React.createElement("div", { className: 'keyboard-controls__row mb-1' },
                React.createElement("i", { className: 'fa fa-gamepad' }),
                " Drag to rotate the globe"),
            React.createElement("div", { className: 'keyboard-controls__row mb-1' },
                React.createElement("i", { className: 'fa fa-gamepad' }),
                " Tap + / - buttons to zoom")));
    }
    return (controls.isOpen) ?
        React.createElement("div", { className: `keyboard-controls ${controls.controlsClass}` }, instructions) : null;
};
exports.default = KeyboardControls;
