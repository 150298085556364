"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOAD_STACKOVERFLOW = 'LOAD_STACKOVERFLOW';
exports.FADE_MODAL = 'FADE_MODAL';
exports.ATTACH_MODAL = 'ATTACH_MODAL';
exports.SET_SEARCH_BAR_STATUS = 'SET_SEARCH_BAR_STATUS';
exports.UPDATE_SEARCH_DATA = 'UPDATE_SEARCH_DATA';
exports.UPDATE_ERROR_MESSAGE = 'UPDATE_ERROR_MESSAGE';
exports.UPDATE_SEARCH_VALUE = 'UPDATE_SEARCH_VALUE';
exports.CLEAR_SEARCH = 'CLEAR_SEARCH';
exports.LOADING_STATUS = 'LOADING_STATUS';
exports.LOADING_PERCENTAGE = 'LOADING_PERCENTAGE';
exports.HOVERED_OVER_OBJECT = 'HOVERED_OVER_OBJECT';
exports.TOGGLE_SEARCH = 'TOGGLE_SEARCH';
exports.TOGGLE_PAUSE = 'TOGGLE_PAUSE';
exports.FADE_CONTROLS = 'FADE_CONTROLS';
exports.ATTACH_CONTROLS = 'ATTACH_CONTROLS';
exports.SET_PAUSE = 'SET_PAUSE';
