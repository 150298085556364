"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Modal_1 = require("../constants/Modal");
const Modal_2 = require("../components/Modal");
const Canvas_1 = require("../components/Canvas");
const LoadingBar_1 = require("../components/LoadingBar");
const Profile_1 = require("../components/Profile");
const ContactFormContainer_1 = require("../containers/ContactFormContainer");
const KeyboardControls_1 = require("../components/KeyboardControls");
const ResumeInformation_1 = require("../components/ResumeInformation");
const loadModalComponent = (component = '', props) => {
    switch (component) {
        case Modal_1.default.CONTACT_FORM:
            return React.createElement(ContactFormContainer_1.default, null);
        case Modal_1.default.KEYBOARD_CONTROLS:
            return React.createElement(KeyboardControls_1.default, { onCancel: props.actions.closeModal, isMobile: props.isMobile });
        default:
            return React.createElement(ResumeInformation_1.default, Object.assign({}, props));
    }
};
const InteractiveResume = (props) => {
    const { pause, isMobile, isHovered, controls, toggledPause, loading, profileImage, stackOverflow, modal, searchBar, actions, } = props;
    return (React.createElement("div", null,
        React.createElement(Profile_1.default, { profileImage: profileImage, loading: loading, stackOverflow: stackOverflow }),
        React.createElement(LoadingBar_1.default, { percentage: loading.percentage }),
        React.createElement(Canvas_1.default, { isPaused: actions.onPause, pause: pause, isMobile: isMobile, updateLoadingBar: actions.loadingPercentage, isHovered: isHovered, hoveredOverObject: actions.hoveredOverObject, openModal: actions.openModal, modalIsOpen: modal.isOpen, togglePause: actions.togglePause, toggledPause: toggledPause, toggleControls: actions.toggleControls, controls: controls, searchBarIsActive: searchBar.isActive, loadingStatus: actions.loadingStatus }),
        React.createElement(Modal_2.default, { state: modal }, loadModalComponent(modal.modalComponent, props))));
};
exports.default = InteractiveResume;
