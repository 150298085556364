"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const es6Polyfill = require("es6-object-assign");
es6Polyfill['polyfill']();
const React = require("react");
const react_dom_1 = require("react-dom");
const react_redux_1 = require("react-redux");
const react_router_1 = require("react-router");
const react_router_redux_1 = require("react-router-redux");
const history_1 = require("history");
const store_1 = require("./store");
const InteractiveResumeContainer_1 = require("./containers/InteractiveResumeContainer");
const detector_1 = require("./utils/detector");
const NoWebGLMessage_1 = require("./components/NoWebGLMessage");
const browserHistory = history_1.createBrowserHistory();
const history = react_router_redux_1.syncHistoryWithStore(browserHistory, store_1.default);
const container = document.getElementById('interactive-resume');
if (container && detector_1.default.webgl) {
    react_dom_1.render((React.createElement(react_redux_1.Provider, { store: store_1.default },
        React.createElement(react_router_1.Router, { history: history },
            React.createElement(react_router_1.Route, { path: '/', component: InteractiveResumeContainer_1.default })))), container);
}
else if (!detector_1.default.webgl) {
    react_dom_1.render(React.createElement(NoWebGLMessage_1.default, null), document.getElementById('loader__message'));
}
