"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Event {
    constructor() {
        this.topics = {};
    }
    subscribe(topic, listener) {
        if (!this.topics[topic]) {
            this.topics[topic] = [];
        }
        this.topics[topic].push(listener);
    }
    publish(topic, data = {}) {
        if ((this.topics[topic] || []).length < 1) {
            return;
        }
        this.topics[topic].forEach(function (listener) {
            listener(data);
        });
    }
}
exports.default = Event;
