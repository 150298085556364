"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const quaternion = new THREE.Quaternion();
const zAxis = new THREE.Vector3(0, 0, 1);
const yAxis = new THREE.Vector3(0, 1, 0);
const flyPlane = (canvas, axis, angle) => {
    const { plane } = canvas.models;
    quaternion.setFromAxisAngle(axis, angle);
    plane.quaternion.premultiply(quaternion);
};
const orbitSatellite = (canvas, axis, angle) => {
    const { satellite } = canvas.models;
    quaternion.setFromAxisAngle(axis, angle);
    satellite.quaternion.premultiply(quaternion);
};
const animateElements = (canvas) => {
    if (canvas.pause) {
        return;
    }
    orbitSatellite(canvas, yAxis, canvas.moveSpeed * 2);
    flyPlane(canvas, zAxis, -canvas.moveSpeed / 2);
};
exports.default = animateElements;
