"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Actions = require("../constants/Generic");
exports.headerMenuReducer = (state = {
    stackOverflow: {
        reputation: 0,
    }
}, action) => {
    const { type, payload } = action;
    const nextState = Object.assign({}, state);
    switch (type) {
        case Actions.LOAD_STACKOVERFLOW:
            if (payload.items[0]) {
                nextState['stackOverflow'] = payload.items[0];
            }
            return nextState;
        default:
            return state;
    }
};
