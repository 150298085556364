"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const es6_promise_1 = require("es6-promise");
const Animations_1 = require("./Animations");
exports.createTexture = (textureName) => {
    return new THREE.MeshPhongMaterial({
        map: new THREE.TextureLoader().load(`/assets/textures/${textureName}.png`),
        transparent: true,
    });
};
exports.createColor = (color, opacity = 1) => new THREE.MeshLambertMaterial({
    color,
    opacity,
});
const loadPercentage = (canvas, e, fileSize) => {
    const triangles = document.querySelectorAll('.loader__svg .invisible');
    const totalTriangles = document.querySelectorAll('.loader__svg path').length;
    canvas.loading = Math.round(100 * (e.loaded / fileSize));
    if (totalTriangles > 0) {
        const remainingTriangles = triangles.length;
        const remainingPercentage = Math.round(100 * remainingTriangles / totalTriangles);
        [].forEach.call(triangles, (path) => {
            if ((Math.random() < (e.loaded / e.total)) && remainingPercentage > 90 - canvas.loading) {
                setTimeout(() => {
                    path.classList.remove('invisible');
                    path.classList.add('visible');
                }, 100 * Math.random());
            }
        });
    }
    return canvas.loading;
};
exports.loadPlanet = (canvas) => {
    const loader = new THREE.ObjectLoader();
    const red = exports.createColor(0xE72526);
    const white = exports.createColor(0xFFFFFF);
    const fileSize = 12373083;
    return new es6_promise_1.Promise((resolve) => {
        const planetJson = new XMLHttpRequest();
        planetJson.addEventListener('progress', (e) => {
            canvas.props.updateLoadingBar(loadPercentage(canvas, e, fileSize));
            let status = '';
            let loading = Math.round(100 * (e.loaded / fileSize));
            if (loading < 10) {
                status = `🌍 Generating Planet ${loading}%`;
            }
            else if (loading < 25) {
                status = `💡 Lights ${loading}%`;
            }
            else if (loading < 75) {
                status = `🎥 Cameras ${loading}%`;
            }
            else if (loading < 100) {
                status = `🎬 Action ${loading}%`;
            }
            else if (loading === 100) {
                status = '🎟 Finalising Setup';
            }
            canvas.event.publish('loading/status', `${status}`);
        });
        planetJson.addEventListener('load', (e) => {
            const mesh = JSON.parse(e.target['responseText']);
            loader.parse(mesh, (planet) => {
                planet.traverse(child => {
                    if (child instanceof THREE.Mesh) {
                        switch (true) {
                            case /bridge/.test(child.name):
                                child.material = red;
                                break;
                            case /cloud/.test(child.name):
                                child.material = white;
                                break;
                            case /planet/.test(child.name):
                                child.material = exports.createTexture('planet-x505');
                                break;
                            case /penguin/.test(child.name):
                                child.material = exports.createTexture('penguin');
                                break;
                            case /coniferous-snow/.test(child.name):
                                child.material = exports.createTexture('coniferous-snow');
                                break;
                            case /coniferous/.test(child.name):
                                child.material = exports.createTexture('coniferous');
                                break;
                            case /npm-logo/.test(child.name):
                            case /gulp-cup/.test(child.name):
                                child.material = exports.createTexture('gulp-cup');
                                break;
                            case /cherry-blossom/.test(child.name):
                                child.material = exports.createTexture('cherry-blossom');
                                break;
                            case /palm-tree/.test(child.name):
                                child.material = exports.createTexture('palm-tree');
                                break;
                            case /react/.test(child.name):
                                child.material = exports.createTexture('react');
                                break;
                            case /palm-tree/.test(child.name):
                                child.material = exports.createTexture('palm-tree');
                                break;
                            case /sass/.test(child.name):
                                child.material = exports.createColor(0xCD6799);
                                break;
                            default:
                                child.material = exports.createTexture(child.name);
                        }
                    }
                });
                resolve(planet);
            });
        });
        planetJson.open('GET', '/assets/models/planet-x505.json');
        planetJson.send();
    }).then((planet) => {
        canvas.models.planet = planet;
        canvas.environment.add(planet);
        canvas.scene.add(canvas.environment);
    }).then(() => {
        canvas.models.satellite = canvas.scene.getObjectByName('satellite');
        canvas.models.plane = canvas.scene.getObjectByName('plane');
        canvas.event.subscribe('animate', Animations_1.default);
    }).catch(e => console.log('Error', e));
};
exports.default = exports.loadPlanet;
