"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Actions = require("../constants/ContactForm");
const formInputState = {
    isActive: false,
    valid: false,
    value: '',
    message: '',
    state: 'danger',
};
const validateInput = (payload) => {
    const { field, value, } = payload;
    switch (field) {
        case 'givenName':
        case 'familyName':
        case 'subject':
        case 'message':
            return (value.length > 0);
        case 'email':
            return /\S+@\S+\.\S+/.test(value);
        default:
            return false;
    }
};
const validateForm = (state) => {
    const keys = Object.keys(state);
    let valid = keys.length;
    keys.forEach((key) => {
        switch (key) {
            case 'valid':
            case 'isSending':
            case 'sentEmail':
                valid--;
                return;
            default:
                if (!state[key].valid) {
                    valid++;
                }
                else {
                    valid--;
                }
                return;
        }
    });
    return valid === 0;
};
exports.contactFormReducer = (state = {
    isSending: false,
    sentEmail: false,
    valid: false,
    givenName: Object.assign({}, formInputState),
    familyName: Object.assign({}, formInputState),
    email: Object.assign({}, formInputState),
    subject: Object.assign({}, formInputState),
    message: Object.assign({}, formInputState),
}, action) => {
    const { type, payload } = action;
    const nextState = Object.assign({}, state);
    switch (type) {
        case Actions.UPDATE_INPUT:
            const { field, value, } = payload;
            const valid = validateInput(payload);
            if (field === 'email') {
                nextState[field].value = `${value.toLowerCase()}`;
            }
            else {
                nextState[field].value = `${value.charAt(0).toUpperCase() + value.slice(1)}`;
            }
            nextState[field].valid = valid;
            nextState[field].isActive = true;
            return nextState;
        case Actions.VALIDATE_FORM:
            nextState.valid = validateForm(nextState);
            return nextState;
        case Actions.SENDING_EMAIL:
            nextState.isSending = payload;
            return nextState;
        case Actions.SEND_EMAIL:
            nextState.sentEmail = payload;
            return nextState;
        default:
            return state;
    }
};
