"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ProfileImage = ({ profileImage }) => {
    return (React.createElement("div", { className: 'profile-image' },
        React.createElement("div", { className: 'profile-image__image', style: {
                backgroundImage: `url(${profileImage})`
            } })));
};
exports.default = ProfileImage;
