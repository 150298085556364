"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ZoomControls = ({ zoom }) => {
    return (React.createElement("div", { className: `zoom-controls` },
        React.createElement("span", { className: 'btn-secondary', title: 'Zoom', onClick: () => {
                zoom(+0.5);
            } },
            React.createElement("i", { className: 'fa fa-search-plus' })),
        React.createElement("span", { className: 'btn-secondary', title: 'Zoom', onClick: () => {
                zoom(-0.5);
            } },
            React.createElement("i", { className: 'fa fa-search-minus' }))));
};
exports.default = ZoomControls;
