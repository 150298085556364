"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Actions = require("../constants/Generic");
exports.interactiveResumeReducer = (state = {
    pause: false,
    isMobile: 'ontouchstart' in document.documentElement
        || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0,
    controls: {
        isOpen: false,
        controlsClass: '',
    },
    isHovered: false,
    toggledPause: false,
    loading: {
        status: '🖥 Waiting to Connect',
        percentage: 0,
    },
    updatedAt: null,
    profileImage: '/assets/images/me.jpg',
    modal: {
        isOpen: false,
        modalComponent: '',
        modalClass: '',
        modalContent: {
            header: '',
            body: '',
            footer: '',
        }
    },
    searchBar: {
        isOpen: false,
        isActive: false,
        currentValue: '',
        data: [],
    },
}, action) => {
    const { type, payload } = action;
    let nextState = Object.assign({}, state, { updatedAt: Date.now() });
    switch (type) {
        case Actions.SET_PAUSE:
            nextState.pause = payload;
            return nextState;
        case Actions.ATTACH_CONTROLS:
            nextState.controls.isOpen = !nextState.controls.isOpen;
            return nextState;
        case Actions.FADE_CONTROLS:
            nextState.controls.controlsClass = !!nextState.controls.controlsClass ? '' : 'in';
            return nextState;
        case Actions.TOGGLE_PAUSE:
            nextState.toggledPause = !nextState.toggledPause;
            return nextState;
        case Actions.LOADING_PERCENTAGE:
            nextState.loading.percentage = payload;
            return nextState;
        case Actions.LOADING_STATUS:
            nextState.loading.status = payload;
            return nextState;
        case Actions.ATTACH_MODAL:
            nextState.searchBar.isOpen = false;
            nextState.controls.isOpen = false;
            nextState.controls.controlsClass = '';
            nextState.modal.isOpen = payload.status;
            nextState.modal.modalComponent = payload.object.name;
            nextState.pause = payload.status;
            return nextState;
        case Actions.FADE_MODAL:
            nextState.modal.modalClass = payload;
            return nextState;
        case Actions.TOGGLE_SEARCH:
            nextState.searchBar.isOpen = !nextState.searchBar.isOpen;
            return nextState;
        case Actions.SET_SEARCH_BAR_STATUS:
            nextState.searchBar.isActive = payload;
            return nextState;
        case Actions.UPDATE_SEARCH_DATA:
            nextState.searchBar.data = payload.hits;
            return nextState;
        case Actions.UPDATE_SEARCH_VALUE:
            nextState.searchBar.currentValue = payload;
            return nextState;
        case Actions.CLEAR_SEARCH:
            nextState.searchBar.currentValue = '';
            nextState.searchBar.data = [];
            return nextState;
        case Actions.HOVERED_OVER_OBJECT:
            nextState.isHovered = payload;
            return nextState;
        default:
            return state;
    }
};
