"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Trianglify = require("trianglify");
const loadBackground = (element = 'interactive-resume', cell_size = 55, variance = 1, width = window.innerWidth, height = window.innerHeight) => {
    return new Promise((resolve) => {
        const pattern = Trianglify({
            width,
            height,
            cell_size,
            variance,
        });
        const background = pattern.canvas();
        background.classList.add('background');
        document.getElementById(element).appendChild(background);
        resolve();
    });
};
exports.default = loadBackground;
