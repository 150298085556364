"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const NoWebGl = () => {
    return (React.createElement("div", { className: 'col-10 col-sm-8 col-md-6 col-lg-5 col-xl-3 mx-auto' },
        React.createElement("p", null, "It appears that your browser does not support WebGl."),
        React.createElement("p", null, "Unfortunately this website requires WebGl to load properly."),
        React.createElement("p", null,
            React.createElement("i", { className: 'fa fa-file-pdf-o' }),
            "\u00A0",
            React.createElement("a", { href: '/assets/resume/SimonMo.co.uk__resume-2017.pdf', title: 'Download my R\u00E9sum\u00E9', target: '_blank' }, "View PDF R\u00E9sum\u00E9"))));
};
exports.default = NoWebGl;
