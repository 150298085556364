"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const loadScene = (canvas) => {
    return new Promise((resolve) => {
        const domElement = canvas.renderer.domElement;
        canvas.renderer.setPixelRatio(window.devicePixelRatio);
        canvas.renderer.setSize(window.innerWidth, window.innerHeight);
        canvas.renderer.setClearColor(0xffffff, 0);
        canvas.container = document.querySelector('.canvas-interactive-resume');
        canvas.container.appendChild(domElement);
        canvas.dayLight.position.set(500, 500, 300);
        canvas.nightLight.position.set(-200, -100, 100);
        canvas.scene.add(canvas.dayLight);
        canvas.scene.add(canvas.nightLight);
        canvas.scene.add(canvas.ambientLight);
        canvas.camera.position.set(0, 0, 600);
        resolve();
    }).catch(e => new Error(e));
};
exports.default = loadScene;
